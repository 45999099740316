<template>
  <PageContent
    class="fiche-liste"
    :nav-titles="navTitles"
    :prev-page="prevPage"
  >
    <template #header-left>
      <ButtonClassic
        v-tooltip="{
          content: $t('general.renommer'),
          delay: { show: 800, },
        }"
        v-matomo-log-click="['liste_renommer', ]"
        variant="special"
        color="primary"
        icon="right"
        size="small"
        :disabled="isImpersonating"
        @click="$modal.show('modal_set_name')"
      >
        <template #right-icon>
          <UilPen class="icon-small" />
        </template>
      </ButtonClassic>
    </template>
    <template
      v-if="!isLoading"
      #header-right
    >
      <ButtonGroup>
        <ButtonClassic
          v-matomo-log-click="['liste_pdf', ]"
          balise-type="a"
          variant="solid"
          icon="right"
          :ext-link="urlPdf"
          :disabled="!objectInfos?.lignes?.length"
        >
          <template #right-icon>
            <IconPDF class="icon-medium" />
          </template>
        </ButtonClassic>
        <ButtonClassic
          variant="solid"
          icon="right"
          :disabled="!objectInfos?.lignes?.length"
          @click="
            copyToClipboard(`${currentUrl}?id_organisme=${organismeActuel.id}`)
          "
        >
          <template #right-icon>
            <UilShareAlt />
          </template>
        </ButtonClassic>
      </ButtonGroup>
    </template>

    <template
      v-if="!isLoading && objectInfos?.lignes?.length"
      #action-bar
    >
      <div
        id="zone_buttons"
        class="flex-vcenter"
      >
        <div class="actions centered">
          <ButtonClassic
            v-if="actionsGlobales[0].actions.length === 1"
            variant="special"
            :label="actionsGlobales[0].actions[0].label"
            color="primary"
            icon="left"
            :disabled="actionsGlobales[0].actions[0].disabled"
            @click="handleActions(actionsGlobales[0].actions[0])"
          >
            <template #left-icon>
              <component :is="actionsGlobales[0].actions[0].icon" />
            </template>
          </ButtonClassic>
          <ButtonGroup v-else-if="actionsGlobales[0].actions.length > 1">
            <ButtonClassic
              v-for="action in actionsGlobales[0].actions"
              :key="action.slug"
              variant="solid"
              :label="action.label"
              color="white"
              icon="left"
              :disabled="action.disabled"
              @click="handleActions(action)"
            >
              <template
                v-if="action.icon"
                #left-icon
              >
                <component :is="action.icon" />
              </template>
            </ButtonClassic>
          </ButtonGroup>
        </div>
        <div class="key-actions">
          <div
            v-if="canCreateDevis"
            v-tooltip="{
              content: tooltipDisabledTitle,
              disabled: !toDevisToCommandeDisabled,
            }"
          >
            <ButtonClassic
              v-matomo-log-click="['liste_to_devis_haut', ]"
              variant="solid"
              color="primary"
              :label="$t('action.passer-en-devis')"
              icon="right"
              :disabled="isImpersonating || toDevisToCommandeDisabled"
              @click="handleFirstModalToOpen('devis')"
            >
              <template #right-icon>
                <IconFileDevis />
              </template>
            </ButtonClassic>
          </div>
          <div
            v-if="canOrder"
            v-tooltip="{
              content: tooltipDisabledTitle,
              disabled: !toDevisToCommandeDisabled,
            }"
          >
            <ButtonClassic
              v-matomo-log-click="['liste_to_commande_haut', ]"
              :label="$t('action.commander')"
              color="secondary"
              variant="solid"
              :disabled="isImpersonating || toDevisToCommandeDisabled"
              icon="right"
              @click="handleFirstModalToOpen('commande')"
            >
              <template #right-icon>
                <IconPanierFleche />
              </template>
            </ButtonClassic>
          </div>
          <ContextMenu
            :bloc-actions-globales="actionsGlobales"
            :bloc-actions-selections="actionsSelections"
            :selections="selections"
            @click-action="handleActions($event)"
          />
        </div>
      </div>
      <p
        v-if="hasNumerique"
        class="text-small italic no-refundable"
      >
        <UisExclamationTriangle />
        {{ $t("licence.licences-numeriques-ni-echangeables-ni-remboursables") }}
      </p>
    </template>

    <template
      v-if="!isLoading"
      #content
    >
      <template v-if="objectInfos?.lignes?.length">
        <Toast
          v-if="isCommandeClosed"
          :title="$t('toast.arret-commandes')"
          :content="$t('commande.les-commandes-sont-fermees')"
          variant="error"
          show-icon
          hide-close-btn
        />
        <BlocLignesCatalogues
          :object-infos="objectInfos"
          :catalogues="catalogues"
          @change-line="handleChangeLine($event)"
          @delete-line="!isImpersonating ? deleteLine($event) : null"
          @sort="handleSort($event)"
        />
        <TableTotal
          :object-infos="objectInfos"
          :message-numerique="hasNumerique"
        />
        <ListeActions
          v-matomo-log-click="['liste_to_devis_bas', ]"
          :approbation-tooltip="{
            title: tooltipDisabledTitle,

            placement: 'top',
            disabled: !toDevisToCommandeDisabled,
          }"
          button-status="primary"
          :button-label="$t('action.passer-en-devis')"
          :button-disabled="isImpersonating || toDevisToCommandeDisabled"
          @click-btn-status-approb="handleFirstModalToOpen('devis')"
          @click-action="handleActions($event)"
        >
          <template #icon>
            <IconFileDevis />
          </template>
          <template
            v-if="canOrder"
            #additional-buttons
          >
            <div
              id="cmd_btn"
              v-tooltip="{
                content: tooltipDisabledTitle,
                disabled: !toDevisToCommandeDisabled,
              }"
            >
              <ButtonClassic
                v-matomo-log-click="['liste_to_commande_bas', ]"
                :label="$t('action.commander')"
                color="secondary"
                variant="solid"
                :disabled="isImpersonating || toDevisToCommandeDisabled"
                size="large"
                icon="right"
                @click="handleFirstModalToOpen('commande')"
              >
                <template #right-icon>
                  <IconPanierFleche />
                </template>
              </ButtonClassic>
            </div>
          </template>
        </ListeActions>
      </template>

      <template v-else>
        <div class="empty-page">
          <h2 class="s4 light">
            {{ $t("liste.votre-liste-est-vide") }}
          </h2>
          <img
            :src="
              require('@lde/core_lde_vue/dist/assets/media/illus/illus_cart.svg')
            "
            :alt="$t('general.alt-image-illustration')"
          />
        </div>
      </template>

      <template v-if="objectInfos">
        <ModalListesOffresIndispos
          :lignes-indisponibles="lignesIndisponibles"
          :lignes-pas-dans-catalogue="lignesPasDansCatalogue"
        />
        <ModalDevisCommande
          source-type="liste"
          :destination-type="destinationType"
          :data="objectInfos"
          :all-cgv="allCgv"
          :erreur="errorModalDevisCommande"
          @bascule-liste="basculeListe($event)"
          @submit="
            $event.destinationType === 'devis' ? toDevis($event) : order($event)
          "
        />
        <ModalSetName
          :libelle="objectInfos.libelle"
          variant="liste"
          @submit="renameList($event)"
        />
        <ModalConfirmDeletion
          :to-delete="[objectInfos, ]"
          variant="liste"
          @confirm="handleAcceptAction('deletion')"
        />
        <ModalListesConfirmTransfer
          :listes="[objectInfos, ]"
          @confirm="handleAcceptAction('transfer', $event)"
        />
        <ModalListesSelection
          id="modal_listes_selection_move"
          :listes="activeLists"
          @move-to-list="handleMoveLines($event)"
          @open-creation-liste="$modal.show('modal_listes_creation')"
        />
        <ModalDuplicateDevisCommande
          :doublon-infos="doublonInfos"
          @force="forceOrder"
        />
      </template>
      <ModalListesInformTransfer
        v-if="objectInfos"
        :liste="objectInfos"
        @hide="setTransferModalRead()"
      />
      <ModalAntares :commande="objectInfos" />
    </template>

    <template
      v-else
      #content
    >
      <div>
        <InfiniteLoader />
      </div>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonClassic,
  Toast,
  PageContent,
  InfiniteLoader,
} from "@lde/core_lde_vue";

import ContextMenu from "@/components/ContextMenu.vue";
import TableTotal from "@/components/table/TableTotal.vue";
import ListeActions from "@/components/listes_devis_commandes/ListeActions.vue";
import ModalDevisCommande from "@/components/modals/ModalDevisCommande.vue";
import ModalSetName from "@/components/modals/ModalSetName.vue";
import ModalConfirmDeletion from "@/components/modals/ModalConfirmDeletion.vue";
import ModalListesConfirmTransfer from "@/components/modals/ModalListesConfirmTransfer.vue";
import ModalListesInformTransfer from "@/components/modals/ModalListesInformTransfer.vue";
import ModalListesSelection from "@/components/modals/ModalListesSelection.vue";
import ModalListesOffresIndispos from "@/components/modals/ModalListesOffresIndispos.vue";
import ModalAntares from "@/components/modals/ModalAntares.vue";
import ModalDuplicateDevisCommande from "@/components/modals/ModalDuplicateDevisCommande.vue";

import IconFileDevis from "@/components/icons/IconFileDevis.vue";
import IconBookToDevisRight from "@/components/icons/IconBookToDevisRight.vue";
import IconPanierFleche from "@/components/icons/IconPanierFleche.vue";
import {
  UilTrashAlt,
  UilTimesCircle,
  UilPen,
} from "@iconscout/vue-unicons";

import mixinDisponibilite from "@/mixins/mixinDisponibilite";
import mixinFicheListeDevisCommandes from "@/mixins/mixinFicheListeDevisCommandes";
import DuplicateInfos from "@/mixins/mixinDuplicateModal";

import config from "@/config";

import { confirmOrganismeOnTesting, changePageTitle } from "@/modules/utils";
import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Vue d'une liste en particulier avec toutes ses informations.
 */
export default {
  name: "FicheListe",
  components: {
    PageContent,
    ButtonClassic,
    Toast,
    ContextMenu,
    TableTotal,
    ListeActions,
    ModalDevisCommande,
    ModalSetName,
    ModalConfirmDeletion,
    ModalListesConfirmTransfer,
    ModalListesInformTransfer,
    ModalListesSelection,
    ModalListesOffresIndispos,
    ModalDuplicateDevisCommande,
    InfiniteLoader,
    IconFileDevis,
    IconPanierFleche,
    UilPen,
    ModalAntares,
  },
  mixins: [mixinDisponibilite, mixinFicheListeDevisCommandes, DuplicateInfos],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevPage = from;
    });
  },
  data() {
    return {
      product: {
        offre_defaut: {},
      },
      prevPage: null,
      debounceTimer: null,
      budget: {},
      changeLineTimeout: null,
      destinationType: "devis",
      controller: new AbortController(),
      totalRows: 0,
      saveOrderBody: null,
      errorModalDevisCommande: false,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "user", "activeLists"]),
    lignesIndisponibles() {
      return this.objectInfos.lignes.filter(
        (ligne) => !this.isOfferAvailable(ligne.offre),
      );
    },
    lignesPasDansCatalogue() {
      return this.objectInfos.lignes.filter(
        ({ offre }) => offre.manuel_numerique && !offre?.catalogue_actif,
      );
    },
    urlPdf() {
      return `${config.api.baseUrl}/liste/${this.$route.params.id}/pdf/`;
    },
    canCreateDevis() {
      return this.isAllowed("creer_devis");
    },
    canOrder() {
      return this.isAllowed("commander");
    },
    tooltipDisabledTitle() {
      let tooltip = null;
      if (this.isCommandeClosed) {
        tooltip = this.$t("commande.les-commandes-sont-fermees");
      } else if (
        this.objectInfos?.lignes.some(
          (element) => element.offre.manuel_numerique
            && element.quantite < element.offre.quantite_min
            && !element.raison_minima,
        )
      ) {
        tooltip = this.$t("liste.verifiez-minima");
      }
      return tooltip;
    },
    toDevisToCommandeDisabled() {
      return (
        this.isCommandeClosed
        || this.objectInfos?.lignes.some(
          (element) => element.offre.manuel_numerique
            && element.quantite < element.offre.quantite_min
            && !element.raison_minima,
        )
      );
    },
    selections() {
      return this.objectInfos.lignes.filter((l) => l._checked);
    },
    actionsGlobales() {
      return [
        {
          title: this.$t("context-menu.actions-applicables-a-une-liste"),
          actions: [
            {
              slug: "transfer_list",
              label: this.$t("liste.envoyer"),
              icon: IconBookToDevisRight,
              disabled: this.isImpersonating,
            },
            {
              slug: "delete_list",
              label: this.$t("action.supprimer"),
              icon: UilTrashAlt,
              disabled: this.isImpersonating,
            },
          ],
        },
      ];
    },
    actionsSelections() {
      return [
        {
          slug: "delete_lines",
          label: this.$t("action.tout-supprimer"),
          icon: UilTimesCircle,
          disabled: this.isImpersonating,
        },
        {
          slug: "move_selected",
          label: this.$t("action.deplacer-dans-une-liste"),
          icon: IconBookToDevisRight,
          disabled: this.isImpersonating,
        },
      ];
    },
  },
  async mounted() {
    await this.fetchListe();
  },
  beforeDestroy() {
    this.controller.abort();
  },
  methods: {
    /**
     * Récupère les informations de la liste.
     */
    async fetchListe() {
      this.isLoading = true;

      const params = {
        signal: this.controller.signal,
        panier: false,
      };
      return Api()
        .get(`/liste/${this.$route.params.id}/`, { params })
        .then(({ data }) => {
          if (this.controller.signal.aborted) {
            return false;
          }

          data.lignes.forEach((ligne) => {
            if (ligne.is_complement && !ligne.raison_minima) {
              this.$set(ligne, "raison_minima", "complement");
              this.handleChangeLine(ligne, true);
            }
          });

          this.objectInfos = data;
          this.objectInfos.statut_affiche = "attente_selection";
          this.fetchCgvUrl("liste");
          changePageTitle(
            this.objectInfos.libelle || this.$t("liste.votre-liste"),
            this.$route,
          );

          if (this.objectInfos.depasse_dotation) {
            this.$store
              .dispatch("fetchBudgetOrganisme", this.objectInfos.organisme.id)
              .then((budget) => {
                this.budgetType = budget.type === "QUANTITE" ? "licences" : "euros";
                this.budget = budget;
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.isLoading = false;
          }
          return true;
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            this.$store.commit("setErrorStatus", err.response.status);
          }
        })
        .finally(() => {
          // Si la liste est transférée et que la modale n'a pas encore été affichée
          if (
            this.objectInfos.utilisateur_transfert
            && !this.objectInfos.date_transfert_info_lue
          ) {
            this.$modal.show("modal_listes_inform_transfer");
          }
        });
    },
    /**
     * Gestion des actions du menu contextuel.
     * @param {Object} action objet de l'action du menu.
     */
    handleActions(action) {
      switch (action.slug) {
        case "delete_list":
          // Ouvre la modale de confirmation pour la suppression.
          this.$modal.show("modal_confirm_deletion");
          break;
        case "delete_lines":
          this.handleDeleteLines(
            this.selections.map((l) => l.id),
            this.$t("liste.lignes-bien-supprimees-de-liste"),
          );
          break;
        case "move_selected":
          this.$store.commit(
            "setSelectedLines",
            this.selections.map((l) => ({
              id_offre: l.id,
              quantite: l.quantite,
            })),
          );
          this.$modal.show("modal_listes_selection_move");
          break;
        case "transfer_list":
          this.openTransferModal();
          break;
        default:
          break;
      }
    },
    /**
     * Met à jour une ligne lorqu'elle change (ex : modification de la quantité).
     * @param {Object} line Ligne sélectionnée.
     */
    handleChangeLine(line) {
      this.changeLineTimeout = setTimeout(() => {
        // Recalcule les totaux
        this.objectInfos.total_ttc = 0;
        this.objectInfos.total_ht = 0;
        this.objectInfos.lignes.forEach((ligne) => {
          if (ligne.quantite) {
            this.objectInfos.total_ttc
              += Math.round(
                parseFloat(ligne.offre.prix_editeur) * ligne.quantite * 100,
              ) / 100;
            this.objectInfos.total_ht
              += Math.round(
                parseFloat(ligne.offre.prix_ht) * ligne.quantite * 100,
              ) / 100;
          }
        });

        if (line.quantite <= 0) {
          this.deleteLine(line);
        } else {
          Api()
            .patch(`/ligne_de_liste/${line.id}/`, line)
            .then((res) => {
              const index = this.objectInfos.lignes.findIndex(
                (i) => i.id === res.data.id,
              );
              this.$set(this.objectInfos.lignes, index, res.data);
              this.$toast.success({
                title: this.$t("info.ligne-bien-modifiee"),
              });
            });
        }
      }, config.api.debounceDelay);
    },
    /**
     * Supprime des lignes de liste.
     * @param {Array} ligneIds Ids des lignes qu'on souhaite supprimer.
     * @param {String} successMsg message de succès dans le toast.
     */
    handleDeleteLines(lignesIds, successMsg) {
      Api()
        .post(`/liste/${this.objectInfos.id}/remove_lignes/`, {
          lignes_ids: lignesIds,
        })
        .then(() => {
          this.fetchListe()
            .then(() => {
              this.$toast.success({ title: successMsg });
            })
            .catch((error) => {
              this.$toast.error({
                title: this.$t("info.une-erreur-est-survenue"),
                content: error,
              });
            });
        });
    },
    /**
     * Supprime une ligne de la liste.
     * @param {Object} line Ligne sélectionnée.
     */
    deleteLine(line) {
      this.handleDeleteLines(
        [line.id],
        this.$t("info.ligne-bien-supprimee-de-liste"),
      );
    },
    /**
     * Effectue une requête selon l'action qui a été acceptée.
     * @param {String} type Définit si on supprime ou si on transfère la liste.
     * @param {Object} user Utilisateur vers lesquel transférer la liste le cas échéant.
     */
    handleAcceptAction(type, user = null) {
      this.isLoading = true;
      let promise = Promise.resolve();
      if (type === "deletion") {
        promise = Api().delete(`/liste/${this.objectInfos.id}/`);
      } else if (type === "transfer") {
        promise = Api().post(
          `/liste/${this.objectInfos.id}/transfer_to_user/`,
          { user_id: user.id },
        );
      }

      promise
        .then(() => {
          this.$router.push({ name: "listes_devis_listes" });

          if (type === "deletion") {
            this.$toast.success({
              title: this.$t("liste.liste-bien-supprimee"),
            });
          } else if (type === "transfer") {
            this.$toast.success({ title: this.$t("liste.liste-bien-envoyee") });
          }
        })
        .catch((err) => {
          this.$toast.error({
            title: err.response.data.detail || err.response.data,
          });
        });
    },
    /**
     * Déplace les offres sélectionnées vers une autre liste.
     * @param {Number} listeId Id de la liste de destination.
     */
    handleMoveLines(listeId) {
      if (this.objectInfos.id === listeId) {
        this.$toast.error({
          title: this.$t(
            "liste.la-liste-destination-peut-pas-etre-identique-actuelle",
          ),
        });
      } else {
        this.isLoading = true;
        const lignesIds = this.selections.map((l) => l.id);
        const data = { lignes_ids: lignesIds, liste_destination_id: listeId };

        Api()
          .post(`/liste/${this.$route.params.id}/move_offers/`, data)
          .then((res) => {
            const nb = this.selections.length;
            this.fetchListe().then(() => {
              this.$store.dispatch("fetchActiveLists");
              this.isLoading = false;
              this.$toast.success({
                title: this.$t("liste.vos-articles-ont-ete-deplaces"),
                content: this.$tc(
                  "liste.x-articles-sont-dorenavant-dans-liste",
                  this.selections,
                  { nb, liste: res.data.libelle },
                ),
              });
            });
          });
      }
    },
    /**
     * Renomme la liste.
     * @param {String} libelle Nouveau nom de la liste.
     */
    renameList(libelle) {
      Api()
        .post(`/liste/${this.objectInfos.id}/rename/`, { libelle })
        .then(({ data }) => {
          this.objectInfos = data;
          this.objectInfos.statut_affiche = "attente_selection";
          this.$modal.hide("modal_set_name");
          this.$toast.success({
            title: this.$t("liste.votre-liste-bien-renommee"),
          });
          changePageTitle(
            this.objectInfos.libelle || this.$t("liste.votre-liste"),
            this.$route,
          );
        })
        .catch((err) => {
          this.$toast.error({ title: err.response.data });
        });
    },
    /**
     * Transforme une liste en devis.
     * @param {Object} body Paramètres à envoyer à l'API lors du passage en devis.
     */
    toDevis(body) {
      const execute = () => {
        this.$toast.info({
          title: this.$t("liste.envoi-en-cours"),
          content: this.$t("toast.veuillez-patienter"),
        });

        Api()
          .post(`/liste/${this.objectInfos.id}/to_devis/`, {
            nom: body.libelle,
            demandes_licences: body.licencesSupp,
          })
          .then(({ data: { id } }) => {
            let title = this.$t(
              "liste.liste-soumise-aux-responsables-commandes",
            );
            if (this.hasPerm("can_transmettre_devis")) {
              title = this.$t("liste.liste-bien-passee-en-devis");
            }
            this.$toast.success({ title });

            // Vérifier si tous les produits ont été passés en devis
            const allAvailable = this.objectInfos.lignes?.every((ligne) => {
              const offre = ligne.offre;
              return this.isOfferAvailable(offre);
            });

            if (!allAvailable) {
              this.$toast.warning({
                title: this.$t("liste.references-pas-pu-etre-passees-en-devis"),
              });
            }

            this.$router.push({
              name: "listes_devis_devis_item",
              params: { id },
            });
          })
          .catch((err) => {
            this.$toast.error({
              title:
                err.response?.data || this.$t("info.une-erreur-est-survenue"),
            });
            this.errorModalDevisCommande = true;
          });
      };

      if (confirmOrganismeOnTesting()) {
        execute();
      }
    },
    /**
     * Transforme une liste en commande.
     * @param {Object} body Paramètres à envoyer à l'API lors du passage en commande.
     */
    order(body) {
      if (
        this.objectInfos.lignes?.find(({ offre }) => this.isOfferAvailable(offre))
      ) {
        const execute = () => {
          this.isLoading = true;

          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });

          const params = body;
          if (this.nameInput) {
            params.nom = this.nameInput;
          }

          this.saveOrderBody = params;

          Api()
            .post(`/liste/${this.$route.params.id}/to_commande/`, params)
            .then(({ data: { id } }) => {
              this.$toast.success({
                title: this.$tc("commande.commande-validee", 1),
                content: this.$t("liste.liste-bien-passe-en-commande"),
              });

              this.$router.push({
                name: "commandes_factures_commandes_item",
                params: { id },
              });
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response) {
                if (!this.handleDuplicateModalToOpen(err.response.data)) {
                  this.$toast.error({
                    title:
                      err.response.data
                      || this.$t("info.une-erreur-est-survenue"),
                  });
                  this.errorModalDevisCommande = true;
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        };

        if (confirmOrganismeOnTesting()) {
          execute();
        }
      } else {
        this.$toast.error({
          title: this.$t(
            "liste.votre-liste-ne-peut-pas-etre-passee-en-commande",
          ),
        });
      }
    },
    /**
     * Affiche la modale de passage en devis/commande ou la modale des offres indispos le cas échéant.
     * @param {String} type Type vers lequel la liste va évoluer ("devis" ou "commande").
     */
    handleFirstModalToOpen(type) {
      if (
        type === "commande"
        && this.organismeActuel.id_organisme.toLowerCase() === "24c0100"
      ) {
        return this.$modal.show("modal_antares");
      }

      if (
        this.lignesIndisponibles.length
        || this.lignesPasDansCatalogue.length
      ) {
        this.$modal.show("modal_listes_offres_indispos");
      } else {
        this.destinationType = type;
        this.$modal.show("modal_devis_commande");
      }

      return true;
    },
    /**
     * Ouvre la modale de transfert.
     */
    openTransferModal() {
      if (this.objectInfos.lignes?.length) {
        this.$modal.show("modal_listes_confirm_transfer");
      } else {
        this.$toast.error({
          title: this.$t("liste.impossible-transferer-liste-vide"),
        });
      }
    },
    /**
     * Définit la modale d'information de transfert comme étant lue.
     */
    setTransferModalRead() {
      if (!this.objectInfos.date_transfert_info_lue) {
        Api().patch(`/liste/${this.objectInfos.id}/`, {
          date_transfert_info_lue: this.$moment(),
        });
      }
    },
    /**
     * Bascule la liste vers un autre organisme.
     * @param {Object} org Organisme recevant la liste.
     */
    basculeListe(org) {
      Api()
        .post(`/liste/${this.objectInfos.id}/bascule_to_organisme/`, {
          organisme_id: org.id,
        })
        .then(({ data }) => {
          this.objectInfos = data;
          this.objectInfos.statut_affiche = "attente_selection";
          this.$toast.success({
            title: this.$t("liste.votre-liste-bien-basculee"),
          });
          this.$router.push({ name: "listes_devis_listes" });
        });
    },

    forceOrder(justification) {
      const params = this.saveOrderBody;
      if (params) {
        params.force = true;
        params.justification = justification;
        this.order(params);
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_fiche_liste.scss";
</style>
